<template>
  <el-menu
    :default-active="activeLink"
    background-color="var(--background-color)"
    class="settings-menu"
    :router="true"
  >
    <el-menu-item
      v-for="item in navigation"
      :key="item.value"
      :index="item.value"
      :route="item.value"
      @click="trackNavigation(item.key)"
    >
      <span v-text="$t(item.key)" />
    </el-menu-item>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import { getNavigation } from './navigation'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'

export default {
  setup() {
    const appConfigStore = useAppConfigStore()
    const { featureConfig } = storeToRefs(appConfigStore)
    return { featureConfig }
  },
  data() {
    return {
      activeLink: null
    }
  },
  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    navigation() {
      const navigation = getNavigation({
        featureConfig: this.featureConfig,
        clientAccountConfiguration: this.clientAccountConfiguration
      })
      return navigation
        .filter((item) => {
          return item.enabled === true
        })
        .map((item) => {
          return {
            key: item.key,
            value: item.value
          }
        })
    }
  },
  watch: {
    $route: function (to, from) {
      this.activeLink = to.path
    }
  },
  mounted() {
    this.activeLink = this.$route.path
  },
  methods: {
    trackNavigation(key) {
      this.$ampli.eventWithBaseProps('navigationSecondary', {
        navigation_to: this.$t(key, 'en')
      })
    }
  }
}
</script>

<style scoped>
.settings-menu {
  height: 100%;
  background: var(--background-color-settings-menu);
}

.settings-menu .el-menu-item.is-active {
  background-color: var(--settings-menu-selected-color) !important;
  font-weight: var(--settings-menu-selected-weight);
}
</style>
