<template>
  <th-page-wrapper color="var(--secondary-color)">
    <el-row class="settings-wrap">
      <el-col :span="5">
        <settings-nav />
      </el-col>
      <el-col :span="19" class="settings-content">
        <router-view v-slot="{ Component }">
          <component :is="Component" ref="sub-page" />
        </router-view>
      </el-col>
    </el-row>
    <template #actions>
      <settings-toolbar @save="handleSave" />
    </template>
  </th-page-wrapper>
</template>

<script>
import SettingsNav from './components/settings-nav'
import SettingsToolbar from '../components/settings-toolbar'

export default {
  name: 'POSSettings',
  metaInfo() {
    return {
      title: this.$t('pages.settings.pos.discounts.title')
    }
  },
  components: {
    SettingsNav,
    SettingsToolbar
  },
  beforeRouteLeave(to, from, next) {
    //reset changes in settings that were not saved
    this.resetChanges()
    next()
  },
  beforeRouteUpdate(to, from, next) {
    //reset changes in settings that were not saved
    this.resetChanges()
    next()
  },
  methods: {
    async handleSave() {
      // signal saving process for rendered children views for specific validation only before saving. Using props, e.g. isSaving, won't work here because children being rendered with router-view
      this.$emitter.emit('save-start')
      const validate = this.getValidationFn()

      validate(async (valid) => {
        if (!valid) return

        const successMessage = this.$t('common.success.action.update.single', {
          resource: this.$t('common.resource.configuration.singular')
        })
        const errorMessage = this.$t('common.error.action.update.single', {
          resource: this.$t('common.resource.configuration.singular')
        })
        this.$store.dispatch('Config/prepareData')
        const [err] = (await this.getSaveFn()) || []

        if (err) {
          return this.$logException(err, {
            message: errorMessage
          })
        }

        this.$message({
          message: successMessage,
          type: 'success'
        })
      })
      this.$emitter.emit('save-done')
    },
    getValidationFn() {
      // only a couple of pages have validation methods, so if there's no such, we default to a noop function that returns true just to keep the flow on handleSave/checkLeave
      return this.$refs['sub-page'] &&
        typeof this.$refs['sub-page'].validate === 'function'
        ? this.$refs['sub-page'].validate
        : (fn) => fn(true)
    },
    async getSaveFn() {
      if (typeof this.$refs['sub-page']?.handleSave === 'function') {
        return await this.$refs['sub-page'].handleSave()
      }
      return await this.$store.dispatch('Config/saveClientAccountConfiguration')
    },
    resetChanges() {
      this.$store.dispatch('Config/resetClientAccountConfiguration')
      // Could be that the language was changed and not saved
      this.$rootMaybeChangeLocale(this.$store.getters['Config/getLocale'])
    }
  }
}
</script>

<style scoped>
.settings-wrap,
.settings-wrap > * {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.settings-content {
  padding: 2rem;
  border-right: 1px solid lightgrey;
}
</style>
